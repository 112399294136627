import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { isItemFavourite, saveItemFavourite } from "../../api";
import { AuthContext } from "../../store/auth-context";
import style from "./UserFavourite.module.css";

const UserFavourite = (props) => {
	const [isFavourite, setIsFavourite] = useState(false);

	const authCtx = useContext(AuthContext);

	useEffect(() => {
		const sendIsFavouriteRequest = async (requestData) => {
			const data = await isItemFavourite(requestData);
			setIsFavourite(data);
		};
		if (authCtx.isLoggedIn) {
			sendIsFavouriteRequest({
				token: authCtx.token,
				itemId: props.itemId,
				itemType: props.itemType,
			});
		}
	}, [authCtx.isLoggedIn, props.itemId, props.itemType, authCtx.token]);

	const heartIconClickHandler = () => {
		saveItemFavourite({
			token: authCtx.token,
			itemId: props.itemId,
			itemType: props.itemType,
		});
		setIsFavourite(true);
	};

	const classNames = `${style.heart} 
		${isFavourite ? style["heart-on"] : style["heart-off"]}
		${authCtx.isLoggedIn ? "" : style.disabled}`;

	return (
		<div className={style["user-favourites"]}>
			<FontAwesomeIcon
				icon="fa-solid fa-heart"
				className={classNames}
				onClick={heartIconClickHandler}
			/>
		</div>
	);
};

export default UserFavourite;

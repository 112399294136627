import style from "./RankingItem.module.css";
import { Link } from "react-router-dom";

const RankingPieceItem = (props) => {
	const { id, placeIndex, title, rating } = props.piece;

	return (
		<Link to={`${props.linkUrl}${id}`} className={style.main}>
			<p>{placeIndex}</p>
			<p className={style.name}>{title}</p>
			<p>{rating.toFixed(2)}</p>
		</Link>
	);
};

export default RankingPieceItem;

import { ReactPropTypes } from "react";

const YoutubeEmbed = (props) => {
	return (
		<div>
			<iframe
				width={props.width}
				height={props.height}
				src={props.src}
				allowFullScreen
				title="Embedded youtube"
			/>
		</div>
	);
};

export default YoutubeEmbed;

import * as constants from "./Constants";

const COMPOSERS_API_URL = constants.COMPOSERS_API_URL;
const PIECES_API_URL = constants.PIECES_API_URL;
const MUSICIANS_API_URL = constants.MUSICIANS_API_URL;

const COMPOSERS_SEARCH_API_URL = constants.COMPOSERS_SEARCH_API_URL;
const PIECES_SEARCH_API_URL = constants.PIECES_SEARCH_API_URL;
const MUSICIANS_SEARCH_API_URL = constants.MUSICIANS_SEARCH_API_URL;
const ALL_SEARCH_API_URL = constants.ALL_SEARCH_API_URL;

const LOG_IN_API_URL = constants.LOG_IN_API_URL;
const SIGN_UP_API_URL = constants.SIGN_UP_API_URL;

const RATING_FOR_ITEM_API_URL = constants.RATING_FOR_ITEM_API_URL;

const RANKING_PLACE_FOR_ITEM = constants.RANKING_PLACE_FOR_ITEM;

const ALL_COMPOSERS_ERROR_MSG = constants.ALL_COMPOSERS_ERROR_MSG;
const ALL_PIECES_ERROR_MSG = constants.ALL_PIECES_ERROR_MSG;
const ALL_MUSICIANS_ERROR_MSG = constants.ALL_MUSICIANS_ERROR_MSG;

const COMPOSER_NOT_FOUND_ERR_MSG = constants.COMPOSER_NOT_FOUND_ERR_MSG;
const MUSICIAN_NOT_FOUND_ERR_MSG = constants.MUSICIAN_NOT_FOUND_ERR_MSG;
const PIECE_NOT_FOUND_ERR_MSG = constants.PIECE_NOT_FOUND_ERR_MSG;

const JSON_CONTENT_TYPE = constants.JSON_CONTENT_TYPE;

const CONTENT_TYPE_HEADER = constants.CONTENT_TYPE_HEADER;

const IS_FAVOURITE_ITEM_API_URL = constants.IS_FAVOURITE_ITEM_API_URL;

const SAVE_FAVOURITE_ITEM_API_URL = constants.SAVE_FAVOURITE_ITEM_API_URL;

const TOP_RANKED_SIZE = 10;

const fetchList = async (apiUrl, errorMessage = "SOMETHING WENT WRONG!") => {
	const response = await fetch(apiUrl);
	const data = await response.json();
	if (!response.ok) {
		throw new Error(errorMessage);
	}
	const list = [];
	for (const key in data) {
		list.push(data[key]);
	}
	return data;
};

const fetchSingleObject = async (
	apiUrl,
	errorMessage = "SOMETHING WENR WRONG!"
) => {
	const response = await fetch(apiUrl);
	const data = await response.json();
	if (!response.ok) {
		throw new Error(errorMessage);
	}
	return data;
};

const fetchListWithConfig = async (apiUrl, method = "GET", headers, body) => {
	let response;
	if (method === "GET") {
		response = await apiUrl;
	} else {
		response = await fetch(apiUrl, {
			method: method,
			headers: headers,
			body: body,
		});
	}
	const data = await response.json();
	if (!response.ok) {
		throw new Error("Something went wrong!");
	}
	return data;
};

// RENDITIONS

export const getAllRenditions = async (requestData) => {
	return await fetchList(
		`http://localhost:8080/api/renditions/${requestData.pageNumber}`
	);
};

export const getRenditionById = async (id) => {
	return await fetchSingleObject(
		`http://localhost:8080/api/renditions/details/${id}`
	);
};

// COMPOSERS

export const getAllComposers = async (requestData) => {
	let apiUrl = `${COMPOSERS_API_URL}${requestData.pageNumber}`;
	let method;
	let headers;
	let body;
	if (requestData.isMultipleFiltersOn) {
		apiUrl = `${COMPOSERS_API_URL}filters/${requestData.pageNumber}`;
		method = "POST";
		headers = { "Content-type": JSON_CONTENT_TYPE };
		body = JSON.stringify({
			min: requestData.ratingFilterValues.min,
			max: requestData.ratingFilterValues.max,
			epoch: requestData.epoch,
		});
	} else if (requestData.ratingFilterValues !== undefined) {
		apiUrl = `${COMPOSERS_API_URL}byRating/${requestData.pageNumber}`;
		method = "POST";
		headers = { "Content-type": JSON_CONTENT_TYPE };
		body = JSON.stringify({
			min: requestData.ratingFilterValues.min,
			max: requestData.ratingFilterValues.max,
		});
	} else if (requestData.epoch !== undefined) {
		apiUrl = `${COMPOSERS_API_URL}byEpoch/${requestData.epoch}/${requestData.pageNumber}`;
	}

	return !method
		? fetchList(apiUrl)
		: fetchListWithConfig(apiUrl, method, headers, body);
};

export const getComposerById = async (composerId) => {
	const data = await fetchSingleObject(
		`${COMPOSERS_API_URL}details/${composerId}`
	);
	const { composedPieces } = data;
	return {
		...data,
		pieces: composedPieces,
	};
};

export const getAllComposersCount = async (requestData) => {
	if (requestData === undefined) {
		return fetchSingleObject(`${COMPOSERS_API_URL}count`);
	} else {
		const response = await fetch(`${COMPOSERS_API_URL}count/byRating`, {
			method: "POST",
			headers: {
				"Content-type": JSON_CONTENT_TYPE,
			},
			body: JSON.stringify({
				min: requestData.min,
				max: requestData.max,
			}),
		});
		const data = await response.json();
		if (!response.ok) {
			throw new Error("Something went wrong!");
		}
		return data;
	}
};

export const getTopComposedPieces = async (requestData) => {
	return fetchList(
		`${COMPOSERS_API_URL}details/${requestData.entityId}/pieces/${5}`
	);
};

export const getAllComposedPieces = async (requestData) => {
	return fetchList(`${COMPOSERS_API_URL}details/${requestData.itemId}/pieces`);
};

export const getAllComposersByEpoch = async (requestData) => {
	const response = await fetch(
		`${COMPOSERS_API_URL}byEpoch/${requestData.epoch}/${requestData.pageNumber}`
	);
	const data = response.json();
	if (!response.ok) {
		throw new Error("something went wrong!");
	}
	return data;
};

// PIECES

export const getAllPieces = async (requestData) => {
	let apiUrl = `${PIECES_API_URL}${requestData.pageNumber}`;
	let method;
	let headers;
	let body;
	if (requestData.isMultipleFiltersOn) {
		apiUrl = `${PIECES_API_URL}filters/${requestData.pageNumber}`;
		method = "POST";
		headers = { "Content-type": JSON_CONTENT_TYPE };
		body = JSON.stringify({
			min: requestData.ratingFilterValues.min,
			max: requestData.ratingFilterValues.max,
			epoch: requestData.epoch,
		});
	} else if (requestData.ratingFilterValues !== undefined) {
		apiUrl = `${PIECES_API_URL}byRating/${requestData.pageNumber}`;
		method = "POST";
		headers = { "Content-type": JSON_CONTENT_TYPE };
		body = JSON.stringify({
			min: requestData.ratingFilterValues.min,
			max: requestData.ratingFilterValues.max,
		});
	} else if (requestData.epoch !== undefined) {
		apiUrl = `${PIECES_API_URL}byEpoch/${requestData.epoch}/${requestData.pageNumber}`;
	}
	console.log(apiUrl);
	return !method
		? fetchList(apiUrl)
		: fetchListWithConfig(apiUrl, method, headers, body);
};

export const getAllPiecesCount = async (requestData) => {
	if (requestData === undefined) {
		return fetchSingleObject(`${PIECES_API_URL}count`);
	}
	const response = await fetch(`${PIECES_API_URL}count/byRating`, {
		method: "POST",
		headers: {
			"Content-type": JSON_CONTENT_TYPE,
		},
		body: JSON.stringify({
			min: requestData.min,
			max: requestData.max,
		}),
	});
	const data = await response.json();
	if (!response.ok) {
		throw new Error("something went wrong!");
	}
	return data;
};

export const getPieceById = async (pieceId) => {
	return fetchSingleObject(`${PIECES_API_URL}details/${pieceId}`);
};

export const getTopPieceMusicians = async (requestData) => {
	return fetchList(
		`${PIECES_API_URL}details/${requestData.entityId}/musicians/${5}`
	);
};

export const getAllPieceMusicians = async (requestData) => {
	return fetchList(`${PIECES_API_URL}details/${requestData.itemId}/musicians`);
};

// MUSICIANS

export const getAllMusicians = async (requestData) => {
	if (requestData.ratingFilterValues === undefined) {
		return fetchList(
			`${MUSICIANS_API_URL}${requestData.pageNumber}`,
			MUSICIAN_NOT_FOUND_ERR_MSG
		);
	} else {
		const response = await fetch(
			`${MUSICIANS_API_URL}byRating/${requestData.pageNumber}`,
			{
				method: "POST",
				headers: {
					"Content-type": JSON_CONTENT_TYPE,
				},
				body: JSON.stringify({
					min: requestData.ratingFilterValues.min,
					max: requestData.ratingFilterValues.max,
				}),
			}
		);
		const data = response.json();
		if (!response.ok) {
			throw new Error("Something went wrong!");
		}
		return data;
	}
};

export const getMusicianById = async (musicianId) => {
	const data = await fetchSingleObject(
		`${MUSICIANS_API_URL}details/${musicianId}`
	);
	const { performedPieces } = data;
	return { ...data, pieces: performedPieces };
};

export const getAllMusiciansCount = async () => {
	return await fetchSingleObject(`${MUSICIANS_API_URL}count`);
};

export const getTopPerformedPieces = async (requestData) => {
	return fetchList(
		`${MUSICIANS_API_URL}details/${requestData.entityId}/pieces/${5}`
	);
};

export const getAllPerformedPieces = async (requestData) => {
	return fetchList(`${MUSICIANS_API_URL}details/${requestData.itemId}/pieces`);
};

// SEARCHING

export const getComposersByQuery = async (query) => {
	return await fetchList(`${COMPOSERS_SEARCH_API_URL}${query}`);
};

export const getPiecesByQuery = async (query) => {
	return await fetchList(`${PIECES_SEARCH_API_URL}${query}`);
};

export const getMusiciansByQuery = async (query) => {
	return await fetchList(`${MUSICIANS_SEARCH_API_URL}${query}`);
};

export const getAllByQuery = async (query) => {
	return fetchList(`${ALL_SEARCH_API_URL}${query}`);
};

// RANKING

export const getAllComposersRanked = async (pageNumber) => {
	return await fetchList(`${COMPOSERS_API_URL}ranking/${pageNumber}`);
};

export const getAllPiecesRanked = async (pageNumber) => {
	return await fetchList(`${PIECES_API_URL}ranking/${pageNumber}`);
};

export const getAllMusiciansRanked = async (pageNumber) => {
	return await fetchList(`${MUSICIANS_API_URL}ranking/${pageNumber}`);
};

export const getTop5Pieces = async () => {
	return await fetchList(`${PIECES_API_URL}ranking/top/${TOP_RANKED_SIZE}`);
};

export const getTop5Composers = async () => {
	return await fetchList(`${COMPOSERS_API_URL}ranking/top/${TOP_RANKED_SIZE}`);
};

export const getTop5Musicians = async () => {
	return await fetchList(`${MUSICIANS_API_URL}ranking/top/${TOP_RANKED_SIZE}`);
};

export const getRankingPlaceForItem = async (requestData) => {
	const response = await fetch(
		`${RANKING_PLACE_FOR_ITEM}${requestData.itemType}/${requestData.itemId}`
	);
	const data = await response.json();
	if (!response.ok) {
		throw new Error("Could not find ranking place");
	}
	return data;
};

// AUTH

export const logInRequest = async (logInData) => {
	const response = await fetch(LOG_IN_API_URL, {
		method: "POST",
		headers: {
			"Content-type": JSON_CONTENT_TYPE,
		},
		body: JSON.stringify({
			username: logInData.username,
			password: logInData.password,
		}),
	});
	const data = await response.json();
	if (!response.ok) {
		throw new Error("Could not log in");
	}
	return data;
};

export const signUpRequest = async (signUpData) => {
	const response = await fetch(SIGN_UP_API_URL, {
		method: "POST",
		headers: {
			"Content-type": JSON_CONTENT_TYPE,
		},
		body: JSON.stringify({
			email: signUpData.email,
			username: signUpData.username,
			password: signUpData.password,
		}),
	});
	const data = await response.json();
	if (!response.ok) {
		throw new Error("Could not sign up");
	}
	return data;
};

// USER

export const getRatingForItem = async (requestData) => {
	const response = await fetch(
		`${RATING_FOR_ITEM_API_URL}${requestData.itemType}/${requestData.itemId}`,
		{
			headers: {
				Authorization: `Bearer ${requestData.token}`,
			},
		}
	);
	const data = await response.json();
	if (!response.ok) {
		throw new Error("Could not fetch rating");
	}
	return data;
};

export const saveRatingForItem = async (requestData) => {
	const response = await fetch(
		`${RATING_FOR_ITEM_API_URL}${requestData.itemType}/${requestData.itemId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${requestData.token}`,
				"Content-type": JSON_CONTENT_TYPE,
			},
			body: JSON.stringify({ rating: requestData.rating }),
		}
	);
	const data = await response.json();
	console.log(data);
	if (!response.ok) {
		throw new Error("Could not add new rating");
	}
	return data;
};

// FAVOURITES

const favouriteApiCall = async (apiUrl, requestData) => {
	const response = await fetch(apiUrl, {
		method: "POST",
		headers: {
			"Content-type": JSON_CONTENT_TYPE,
			Authorization: `Bearer ${requestData.token}`,
		},
		body: JSON.stringify({
			itemType: requestData.itemType,
			itemId: requestData.itemId,
		}),
	});
	const data = await response.json();
	if (!response.ok) {
		throw new Error("something went wrong!");
	}
	return data;
};

export const isItemFavourite = async (requestData) => {
	return favouriteApiCall(IS_FAVOURITE_ITEM_API_URL, requestData);
};

export const saveItemFavourite = async (requestData) => {
	return favouriteApiCall(SAVE_FAVOURITE_ITEM_API_URL, requestData);
};

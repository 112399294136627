import { useContext, useEffect, useState } from "react";
import { FilterContext } from "../../store/filter-context";
import style from "./StarsRating.module.css";

const StarRatingFilter = () => {
	const [rating, setRating] = useState(0);
	const [hover, setHover] = useState(0);
	const [isFirstStarClicked, setIsFirstStarClicked] = useState(false);
	const [isSecondStarClicked, setIsSecondStarClicked] = useState(false);
	const [maxRating, setMaxRating] = useState(0);

	const allStars = 10;

	const filterCtx = useContext(FilterContext);

	const onStarClickHandler = (index) => {
		if (!isFirstStarClicked) {
			setIsFirstStarClicked(true);
			setRating(index);
		} else if (isFirstStarClicked && !isSecondStarClicked) {
			setIsSecondStarClicked(true);
			setMaxRating(index);
		} else if (isFirstStarClicked && isSecondStarClicked) {
			setIsSecondStarClicked(false);
			setRating(index);
		}
		if (isFirstStarClicked && !isSecondStarClicked && index === rating) {
			setIsFirstStarClicked(false);
			setIsSecondStarClicked(false);
			setRating(0);
			setMaxRating(0);
			filterCtx.removeRatingFilter();
		}
	};

	useEffect(() => {
		if (isFirstStarClicked && !isSecondStarClicked) {
			filterCtx.addRatingFilter({ min: rating, max: rating + 0.99 });
		} else if (isFirstStarClicked && isSecondStarClicked) {
			if (maxRating < rating) {
				const max = maxRating;
				setMaxRating(rating);
				setRating(max);
				filterCtx.addRatingFilter({ min: max, max: rating });
			} else {
				filterCtx.addRatingFilter({ min: rating, max: maxRating });
			}
		} else if (!isFirstStarClicked && !isSecondStarClicked) {
			filterCtx.removeRatingFilter();
		}
	}, [isFirstStarClicked, isSecondStarClicked, maxRating, rating]);

	const getStarClassname = (index) => {
		if (isFirstStarClicked && isSecondStarClicked) {
			return index >= rating && index <= maxRating ? style.on : style.off;
		} else if (isFirstStarClicked && !isSecondStarClicked) {
			return index === rating ? style.on : style.off;
		}
		return style.off;
	};

	return (
		<div className={style["star-rating"]}>
			{[...Array(allStars)].map((star, index) => {
				index += 1;
				const className = getStarClassname(index);
				return (
					<button
						type="button"
						key={index}
						className={className}
						onClick={() => onStarClickHandler(index)}
						onMouseEnter={() => setHover(index)}
						onMouseLeave={() => setHover(rating)}
					>
						<span className={style.star}>&#9733;</span>
					</button>
				);
			})}
		</div>
	);
};

export default StarRatingFilter;

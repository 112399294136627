import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllRenditions } from "../../api";
import useHttp from "../../hooks/use-http";
import RenditionListItem from "./RenditionListItem";
import { usePagination } from "../../hooks/use-pagination";
import style from "../entities-database/EntitiesDatabase.module.css";
import Pagination from "../UI/Pagination";

const RenditionsList = (props) => {
	const [renditionsList, setRenditionsList] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	const { status, error, data, sendRequest } = useHttp(getAllRenditions, true);

	const { pageNumber, setPageNumber, pagesCount } = usePagination(1, pageCount);

	useEffect(() => {
		sendRequest({ pageNumber });
	}, [pageNumber]);

	useEffect(() => {
		if (status === "completed" && !error) {
			setRenditionsList(data.entityList);
			setPageCount(data.allPagesCount);
		}
	}, [status, error, data, setRenditionsList, setPageCount, pageNumber]);

	const listContent = renditionsList.map((rendition) => {
		return (
			<li key={rendition.id}>
				<Link to={`/renditions/details/${rendition.id}`}>
					<RenditionListItem rendition={rendition} />
				</Link>
			</li>
		);
	});

	return (
		<div className={style["entity-list"]}>
			<ul>{listContent}</ul>
			<Pagination
				pageNumber={pageNumber}
				pagesCount={pagesCount}
				setPageNumber={setPageNumber}
			/>
		</div>
	);

	return <ul>{listContent}</ul>;
};

export default RenditionsList;

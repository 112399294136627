import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageMain from "../layout/PageMain";
import EntitiesDatabaseNav from "./EntitesDatabaseNav";
import style from "./EntitiesDatabase.module.css";
import * as constants from "../../Constants";
import { FilterContext } from "../../store/filter-context";
import ComposersList from "../composers/ComposersList";
import PiecesList from "../pieces/PiecesList";
import MusiciansList from "../musicians/MusiciansList";
import RenditionsList from "../renditions/RenditionsList";

const MUSICIANS_VALUE = constants.MUSICIAN_ENTITY_TYPE;
const PIECE_VALUE = constants.PIECE_ENTITY_TYPE;
const COMPOSER_VALUE = constants.COMPOSER_ENTITY_TYPE;
const RENDITION_VALUE = constants.RENDITION_ENTITY_TYPE;

const EntitiesDatabase = () => {
	const [entityListMain, setEntityListMain] = useState(null);

	const location = useLocation();

	const filterCtx = useContext(FilterContext);

	useEffect(() => {
		if (location.pathname.includes(MUSICIANS_VALUE)) {
			setEntityListMain(<MusiciansList />);
		} else if (location.pathname.includes(PIECE_VALUE)) {
			setEntityListMain(<PiecesList />);
		} else if (location.pathname.includes(COMPOSER_VALUE)) {
			setEntityListMain(<ComposersList />);
		} else if (location.pathname.includes(RENDITION_VALUE)) {
			setEntityListMain(<RenditionsList />);
		}
	}, [location.pathname, setEntityListMain, filterCtx.removeEpochFilter]);

	return (
		<PageMain>
			<div className={style.header}>
				<h1>Euterpio database</h1>
				<EntitiesDatabaseNav
					isEpochFilterOn={!location.pathname.includes(MUSICIANS_VALUE)}
				/>
			</div>
			<div className={style.main}>{entityListMain}</div>
		</PageMain>
	);
};

export default EntitiesDatabase;

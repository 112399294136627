import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllPieces } from "../../api";
import useHttp from "../../hooks/use-http";
import Pagination from "../UI/Pagination";
import PieceListItem from "./PieceListItem";
import { usePagination } from "../../hooks/use-pagination";
import style from "../entities-database/EntitiesDatabase.module.css";
import LoadingSpinner from "../UI/LoadingSpinner";
import { FilterContext } from "../../store/filter-context";

const PiecesList = (props) => {
	const [piecesList, setPiecesList] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	const filterCtx = useContext(FilterContext);

	const { status, error, data, sendRequest } = useHttp(getAllPieces, true);

	const { pageNumber, setPageNumber, pagesCount } = usePagination(1, pageCount);

	useEffect(() => {
		console.log(data);
		if (status === "completed" && !error) {
			if (!data.entityList) {
				setPiecesList(data[0]);
				setPageCount(data[1]);
			} else {
				setPiecesList(data.entityList);
				setPageCount(data.allPagesCount);
			}
		}
	}, [
		status,
		error,
		setPiecesList,
		setPageCount,
		data,
		filterCtx.isRatingFilterOn,
		filterCtx.isEpochFilterOn,
		filterCtx.isMultipleFiltersOn,
	]);

	useEffect(() => {
		if (filterCtx.isMultipleFiltersOn) {
			sendRequest({
				pageNumber,
				ratingFilterValues: filterCtx.ratingFilterValue,
				epoch: filterCtx.epochFilterValue,
				isMultipleFiltersOn: true,
			});
		} else if (filterCtx.isEpochFilterOn) {
			sendRequest({ pageNumber, epoch: filterCtx.epochFilterValue });
		} else if (filterCtx.isRatingFilterOn) {
			sendRequest({
				pageNumber,
				ratingFilterValues: filterCtx.ratingFilterValue,
			});
		} else {
			sendRequest({ pageNumber: pageNumber });
		}
	}, [
		filterCtx.isEpochFilterOn,
		pageNumber,
		filterCtx.epochFilterValue,
		filterCtx.isRatingFilterOn,
		filterCtx.ratingFilterValue,
		filterCtx.isMultipleFiltersOn,
	]);

	const listContent = piecesList.map((piece) => {
		return (
			<li key={piece.id}>
				<Link to={`/pieces/details/${piece.id}`}>
					<PieceListItem piece={piece} />
				</Link>
			</li>
		);
	});

	if (status === "pending") {
		return <LoadingSpinner />;
	}

	return (
		<div className={style["entity-list"]}>
			<ul>{listContent}</ul>
			<Pagination
				pageNumber={pageNumber}
				pagesCount={pagesCount}
				setPageNumber={setPageNumber}
			/>
		</div>
	);
};

export default PiecesList;

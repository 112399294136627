import PageMain from "../layout/PageMain";
import YoutubeEmbed from "../UI/YoutubeEmbed";

const MainPageMain = () => {
	const src = `https://www.youtube.com/embed/dQw4w9WgXcQ?start=10&end=20&autoplay=1`;

	return (
		<PageMain>
			<YoutubeEmbed src={src} />
		</PageMain>
	);
};

export default MainPageMain;

import PageMain from "../layout/PageMain";
import RankingHomeMain from "./RankingHomeMain";

const RankingHomePage = () => {
	return (
		<PageMain>
			<RankingHomeMain />
		</PageMain>
	);
};

export default RankingHomePage;

import { getTop5Composers, getTop5Musicians, getTop5Pieces } from "../../api";
import TopEntityRatingBox from "./TopEntityRatingBox";
import style from "./RankingHomeMain.module.css";

const RankingHomeMain = () => {
	return (
		<div>
			<h1>Ranking</h1>
			<div className={style["ranking-boxes"]}>
				<div className={style["ranking-box"]}>
					<TopEntityRatingBox entityType="PIECES" apiRequest={getTop5Pieces} />
				</div>
				<div className={style["ranking-box"]}>
					<TopEntityRatingBox
						entityType="COMPOSERS"
						apiRequest={getTop5Composers}
					/>
				</div>
				<div className={style["ranking-box"]}>
					<TopEntityRatingBox
						entityType="MUSICIANS"
						apiRequest={getTop5Musicians}
					/>
				</div>
			</div>
		</div>
	);
};

export default RankingHomeMain;

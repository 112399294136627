import { useState, createContext, useEffect } from "react";

export const FilterContext = createContext({
	isRatingFilterOn: false,
	isEpochFilterOn: false,
	ratingFilterValue: null,
	epochFilterValue: null,
	isMultipleFiltersOn: false,
	multipleFiltersValue: null,
	addRatingFilter: (rating) => {},
	removeRatingFilter: () => {},
	addEpochFilter: (epoch) => {},
	removeEpochFilter: () => {},
	removeAllFilters: () => {},
});

export const FilterContextProvider = (props) => {
	const [isRatingFilterOn, setIsRatingFilterOn] = useState(false);
	const [isEpochFilterOn, setIsEpochFilterOn] = useState(false);
	const [ratingFilterValue, setRatingFilterValue] = useState(null);
	const [epochFilterValue, setEpochFilterValue] = useState(null);
	const [isMultipleFiltersOn, setIsMultipleFiltersOn] = useState(false);
	const [multipleFiltersValue, setMultipleFiltersValue] = useState(null);

	const addRatingFilterHandler = (rating) => {
		console.log(rating);
		setRatingFilterValue({
			min: rating.min,
			max: rating.max,
		});
		setIsRatingFilterOn(true);
	};

	const removeRatingFilterHandler = () => {
		setRatingFilterValue(null);
		setIsRatingFilterOn(false);
		setIsMultipleFiltersOn(false);
	};

	const addEpochFilterHandler = (epoch) => {
		setEpochFilterValue(epoch);
		setIsEpochFilterOn(true);
	};

	const removeEpochFilterHandler = () => {
		setEpochFilterValue(null);
		setIsEpochFilterOn(false);
		setIsMultipleFiltersOn(false);
	};

	const removeAllFiltersHandler = () => {
		removeEpochFilterHandler();
		removeRatingFilterHandler();
		setIsMultipleFiltersOn(false);
	};

	useEffect(() => {
		if (isEpochFilterOn && isRatingFilterOn) {
			setMultipleFiltersValue({
				ratingFilterValue,
				epochFilterValue,
			});
			setIsMultipleFiltersOn(true);
		}
	}, [
		isEpochFilterOn,
		isRatingFilterOn,
		ratingFilterValue,
		epochFilterValue,
		setIsMultipleFiltersOn,
	]);

	const contextValue = {
		isRatingFilterOn,
		isEpochFilterOn,
		isMultipleFiltersOn,
		ratingFilterValue,
		epochFilterValue,
		multipleFiltersValue,
		addRatingFilter: addRatingFilterHandler,
		removeRatingFilter: removeRatingFilterHandler,
		addEpochFilter: addEpochFilterHandler,
		removeEpochFilter: removeEpochFilterHandler,
		removeAllFilters: removeAllFiltersHandler,
	};

	return (
		<FilterContext.Provider value={contextValue}>
			{props.children}
		</FilterContext.Provider>
	);
};

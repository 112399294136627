import "./App.css";
import Layout from "./components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import ComposersPage from "./pages/ComposersPage";
import PiecesPage from "./pages/PiecesPage";
import MusiciansPage from "./pages/MusiciansPage";
import RankingPage from "./pages/RankingPage";
import RankingHomePage from "./components/ranking/RankingHomePage";
import LogInPage from "./pages/LogInPage";
import SignUpPage from "./pages/SignUpPage";
import EntityDetailsPage from "./pages/EntityDetailsPage";
import { FilterContextProvider } from "./store/filter-context";
import EntitiesDatabase from "./components/entities-database/EntitiesDatabase";

const HOME_PATH = "/home";
const COMPOSERS_PATH = "/composers";
const PIECES_PATH = "/pieces";
const MUSICIANS_PATH = "/musicians";
const RENDITIONS_PATH = "/renditions";
const LOGIN_PATH = "/log-in";
const SIGNUP_PATH = "/sign-up";

function App() {
	return (
		<Layout>
			<Routes>
				<Route path={HOME_PATH} element={<MainPage />} />
				<Route path={COMPOSERS_PATH} element={<EntitiesDatabase />} />
				<Route path={PIECES_PATH} element={<EntitiesDatabase />} />
				<Route path={MUSICIANS_PATH} element={<EntitiesDatabase />} />
				<Route path={RENDITIONS_PATH} element={<EntitiesDatabase />} />
				<Route
					path={`${COMPOSERS_PATH}/details/:entityId`}
					element={<EntityDetailsPage />}
				/>
				<Route
					path={`${PIECES_PATH}/details/:entityId`}
					element={<EntityDetailsPage />}
				/>
				<Route
					path={`${MUSICIANS_PATH}/details/:entityId`}
					element={<EntityDetailsPage />}
				/>
				<Route
					path={`${RENDITIONS_PATH}/details/:entityId`}
					element={<EntityDetailsPage />}
				/>
				<Route path={`/ranking/:entityType`} element={<RankingPage />} />
				<Route path={"/ranking"} element={<RankingHomePage />} />
				<Route path={LOGIN_PATH} element={<LogInPage />} />
				<Route path={SIGNUP_PATH} element={<SignUpPage />} />
			</Routes>
		</Layout>
	);
}

export default App;

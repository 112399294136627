import { useEffect, useState } from "react";
import style from "../entities-database/EntityListItem.module.css";

const PersonEntityListItem = (props) => {
	const [person, setPerson] = useState(props.person);

	useEffect(() => {
		setPerson(props.person);
	}, [props.person, setPerson]);

	return (
		<div className={style.main}>
			<p className={style["badge-label"]}>{props.entityType}</p>
			<div className={style["item-outer"]}>
				<img
					alt={`${person.firstName} ${person.lastName}`}
					src={`http://localhost:8080/api/images/256/${Math.floor(
						Math.random() * 9
					)}`}
				/>
				<div className={style["item-inner"]}>
					{person.epoch && <p>{person.epoch}</p>}
					<div className={style["item-info"]}>
						<h3>
							{person.firstName} {person.lastName}
						</h3>
						<p>
							{person.firstName} {person.middleName} {person.lastName}
						</p>
						<p>
							{person.dateOfDeath
								? `${person.dateOfBirth} - ${person.dateOfDeath}`
								: `${person.dateOfBirth}`}
						</p>
					</div>
					<div className={style["item-rating"]}>
						<span className={style.star}>&#9733;</span>
						<p className={style["rating-value"]}>{person.rating.toFixed(2)}</p>
						<p>283 231 rates</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PersonEntityListItem;

import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import style from "../../DetailedInfo.module.css";

const ComposerDetailedInfo = (props) => {
	const [composer, setComposer] = useState(props.composer);

	useEffect(() => {
		setComposer(props.composer);
	}, [props.composer]);

	if (composer === null) {
		return <LoadingSpinner />;
	}

	return (
		<div className={style.main}>
			<div>
				<img
					alt={`${composer.firstName} ${composer.lastName}`}
					src={`http://localhost:8080/api/images/256/${Math.floor(
						Math.random() * 9
					)}`}
				/>
			</div>
			<div className={style.info}>
				<div>
					<h4>Full name</h4>
					<p>{`${composer.firstName} ${composer.middleName} ${composer.lastName}`}</p>
				</div>
				<div>
					<h4>Born</h4>
					<p>{composer.dateOfBirth}</p>
				</div>
				{composer.dateOfDeath && (
					<div>
						<h4>Died</h4>
						<p>{composer.dateOfDeath}</p>
					</div>
				)}
				<div>
					<h4>Nationality</h4>
					<p>{composer.nationality}</p>
				</div>
			</div>
		</div>
	);
};

export default ComposerDetailedInfo;

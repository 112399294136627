import style from "./EntityDetails.module.css";

const MainInfo = (props) => {
	return (
		<section className={style["main-info-container"]}>
			<div className={style["main-info"]}>
				<p>{props.entityType}</p>
				<h2>{props.name}</h2>
				<p>{props.date}</p>
			</div>
			<div className={style["main-info-rating"]}>
				<span className={style.star}>&#9733;</span>
				<p className={style["main-info-rating-value"]}>
					{props.rating.toFixed(1)}
				</p>
				<p>200 000 ratings</p>
			</div>
		</section>
	);
};

export default MainInfo;

import { useParams } from "react-router-dom";
import PageMain from "../layout/PageMain";
import style from "./RankingMain.module.css";
import RankingMainNav from "./RankingMainNav";
import RankingList from "./RankingList";

const RankingMain = (props) => {
	const params = useParams();

	const entityType = params.entityType.substring(
		0,
		params.entityType.length - 1
	);

	return (
		<PageMain>
			<div className={style.main}>
				<div className={style.header}>
					<h1>Rankings</h1>
					<RankingMainNav />
				</div>
				<div>
					<RankingList entityType={entityType} />
				</div>
			</div>
		</PageMain>
	);
};

export default RankingMain;

export const PIECE_ENTITY_TYPE = "piece";
export const MUSICIAN_ENTITY_TYPE = "musician";
export const COMPOSER_ENTITY_TYPE = "composer";
export const RENDITION_ENTITY_TYPE = "rendition";

export const COMPOSERS_API_URL_PART = "composers";
export const MUSICIANS_API_URL_PART = "musicians";
export const PIECES_API_URL_PART = "pieces";
export const RENDITION_API_URL_PART = "rendition";

export const API_URL = "http://localhost:8080/api/";
export const SEARCH_API_URL = `${API_URL}search/`;

export const COMPOSERS_API_URL = `${API_URL}composers/`;
export const PIECES_API_URL = `${API_URL}pieces/`;
export const MUSICIANS_API_URL = `${API_URL}musicians/`;

export const COMPOSERS_SEARCH_API_URL = `${SEARCH_API_URL}composers/`;
export const PIECES_SEARCH_API_URL = `${SEARCH_API_URL}pieces/`;
export const MUSICIANS_SEARCH_API_URL = `${SEARCH_API_URL}musicians/`;
export const ALL_SEARCH_API_URL = `${SEARCH_API_URL}all/`;

export const LOG_IN_API_URL = `${API_URL}auth/log-in`;
export const SIGN_UP_API_URL = `${API_URL}auth/sign-up`;

export const RATING_FOR_ITEM_API_URL = `${API_URL}rating/`;

export const RANKING_PLACE_FOR_ITEM = `${API_URL}ranking/place/`;

const FAVOURITE_ITEM_API_URL = `${API_URL}favourites/`;

export const IS_FAVOURITE_ITEM_API_URL = `${FAVOURITE_ITEM_API_URL}`;

export const SAVE_FAVOURITE_ITEM_API_URL = `${FAVOURITE_ITEM_API_URL}save`;

export const ALL_COMPOSERS_ERROR_MSG = "Composers wasn't able to load";
export const ALL_PIECES_ERROR_MSG = "Pieces wasn't able to load";
export const ALL_MUSICIANS_ERROR_MSG = "Musicians wasn't able to load";

export const COMPOSER_NOT_FOUND_ERR_MSG = "Composer can not be found";
export const MUSICIAN_NOT_FOUND_ERR_MSG = "Musician can not be found";
export const PIECE_NOT_FOUND_ERR_MSG = "Piece can not be found";

export const JSON_CONTENT_TYPE = "application/json";

export const CONTENT_TYPE_HEADER = "Content-type";

export const TOP_RANKED_SIZE = 10;

export const EPOCHS = [
	"Early music",
	"Reneissance",
	"Baroque",
	"Classical",
	"Romantic",
	"20th century",
	"Modern",
];

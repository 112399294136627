import { useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import style from "../../DetailedInfo.module.css";

const MusicianDetailedInfo = (props) => {
	const [musician, setMusician] = useState(props.musician);

	useEffect(() => {
		setMusician(props.musician);
	}, [props.musician]);

	if (musician === null) {
		return <LoadingSpinner />;
	}

	return (
		<div className={style.main}>
			<div>
				<img
					alt={`${musician.firstName} ${musician.lastName}`}
					src={`http://localhost:8080/api/images/256/${Math.floor(
						Math.random() * 9
					)}`}
				/>
			</div>
			<div className={style.info}>
				<div>
					<h4>Full name</h4>
					<p>{`${musician.firstName} ${musician.middleName} ${musician.lastName}`}</p>
				</div>
				<div>
					<h4>Born</h4>
					<p>{musician.dateOfBirth}</p>
				</div>
				{musician.dateOfDeath && (
					<div>
						<h4>Died</h4>
						<p>{musician.dateOfDeath}</p>
					</div>
				)}
				<div>
					<h4>Nationality</h4>
					<p>{musician.nationality}</p>
				</div>
			</div>
		</div>
	);
};

export default MusicianDetailedInfo;

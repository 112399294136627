import { useEffect, useState } from "react";
import {
	getAllComposersRanked,
	getAllMusiciansRanked,
	getAllPiecesRanked,
} from "../../api";
import useHttp from "../../hooks/use-http";
import style from "./RankingMain.module.css";
import ListItem from "./ListItem";
import Pagination from "../UI/Pagination";
import { usePagination } from "../../hooks/use-pagination";

const INITIAL_PAGE_NUMBER = 1;
const ELEMENTS_PER_PAGE = 10;

const RankingList = (props) => {
	const [entityList, setEntityList] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	let entityListApiRequest;

	if (props.entityType === "piece") {
		entityListApiRequest = getAllPiecesRanked;
	} else if (props.entityType === "composer") {
		entityListApiRequest = getAllComposersRanked;
	} else if (props.entityType === "musician") {
		entityListApiRequest = getAllMusiciansRanked;
	}

	const { status, error, data, sendRequest } = useHttp(
		entityListApiRequest,
		true
	);

	const { pageNumber, setPageNumber, pagesCount } = usePagination(
		INITIAL_PAGE_NUMBER,
		props.entityType,
		pageCount
	);

	useEffect(() => {
		sendRequest(pageNumber);
	}, [props.entityType, pageNumber]);

	useEffect(() => {
		setPageNumber(1);
	}, [props.entityType]);

	useEffect(() => {
		if (status === "completed" && !error) {
			console.log(data);
			setEntityList(data[0]);
			setPageCount(data[1]);
		}
	}, [status, error, data]);

	let currentRankingPlace = pageNumber * 10 - 10;

	const rankingListContent = entityList.map((entity) => {
		currentRankingPlace++;
		return (
			<ListItem
				entityType={props.entityType}
				entity={entity}
				rankingPlace={currentRankingPlace}
			/>
		);
	});

	return (
		<div className={style["ranking-list"]}>
			<ul>{rankingListContent}</ul>
			<Pagination
				pageNumber={pageNumber}
				pagesCount={pagesCount}
				setPageNumber={setPageNumber}
			/>
		</div>
	);
};

export default RankingList;

import { useContext, useEffect, useState } from "react";
import useHttp from "../hooks/use-http";
import { FilterContext } from "../store/filter-context";

export const usePagination = (initialPageNumber, pagesCount) => {
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [pageCount, setPageCount] = useState(pagesCount);

	const filterCtx = useContext(FilterContext);

	useEffect(() => {
		setPageNumber(1);
	}, [filterCtx.isEpochFilterOn, filterCtx.isRatingFilterOn]);

	useEffect(() => {
		setPageCount(pagesCount);
	}, [pagesCount]);

	return {
		pageNumber,
		setPageNumber,
		pagesCount: pageCount,
	};
};

import { useEffect, useState } from "react";
import style from "./ListItem.module.css";
import { Link } from "react-router-dom";
import { PIECE_ENTITY_TYPE } from "../../Constants";

const ListItem = (props) => {
	const [entity, setEntity] = useState(props.entity);

	useEffect(() => {
		setEntity(props.entity);
	}, [props.entity]);

	const isPerson = props.entityType === PIECE_ENTITY_TYPE ? false : true;

	return (
		<li>
			<Link to={`/${props.entityType}s/details/${entity.id}`}>
				<div className={style.main}>
					<div className={style["ranking-place"]}>
						<p>{props.rankingPlace}</p>
					</div>
					{isPerson && (
						<div>
							<img
								alt={`${entity.firstName} ${entity.lastName}`}
								src={`http://localhost:8080/api/images/256/${Math.floor(
									Math.random() * 9
								)}`}
							/>
						</div>
					)}
					<div className={style["main-inner"]}>
						<div className={style["main-info"]}>
							{isPerson && (
								<>
									<h3>
										{entity.firstName} {entity.lastName}
									</h3>
									<p>{entity.dateOfBirth}</p>
								</>
							)}
							{!isPerson && (
								<>
									<h3>{entity.title}</h3>
									<p>{entity.creationDate}</p>
								</>
							)}
						</div>
						<div className={style.rating}>
							<div className={style["rating-inner"]}>
								<span className="star">&#9733;</span>
								<h5>{entity.rating.toFixed(2)}</h5>
							</div>
							<p>200 000 ratings</p>
						</div>
					</div>
				</div>
			</Link>
		</li>
	);
};

export default ListItem;

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Filters from "./Filters";
import style from "./EntitiesDatabase.module.css";

const EntitiesDatabaseNav = (props) => {
	const [isFiltersDropdownVisible, setIsFiltersDropdownVisible] =
		useState(false);
	const [filterRatingValue, setFilterRatingValue] = useState(0);

	const filtersButtonClickHandler = () => {
		if (!isFiltersDropdownVisible) {
			setFilterRatingValue(0);
		}
		setIsFiltersDropdownVisible(!isFiltersDropdownVisible);
	};

	return (
		<>
			<div className={style.nav}>
				<nav>
					<ul>
						<li>
							<NavLink
								to="/composers"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Composers
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/musicians"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Musicians
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/pieces"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Pieces
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/renditions"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Renditions
							</NavLink>
						</li>
					</ul>
				</nav>
				<div className={style.filters}>
					<button onClick={filtersButtonClickHandler}>
						<FontAwesomeIcon icon="fa-solid fa-filter" />
						Filters
					</button>
				</div>
			</div>
			{isFiltersDropdownVisible && (
				<Filters isEpochFilterOn={props.isEpochFilterOn} />
			)}
		</>
	);
};

export default EntitiesDatabaseNav;

import { useEffect, useState } from "react";
import { getAllMusicians, getMusicianById } from "../../api";
import useHttp from "../../hooks/use-http";
import PageMain from "../layout/PageMain";
import LoadingSpinner from "../UI/LoadingSpinner";
import style from "../entity-details/EntityDetails.module.css";
import MusicianDetailedInfo from "./MusicianDetailedInfo";
import MainInfo from "../entity-details/MainInfo";
import RatingSection from "../entity-details/RatingSection";

const MusicianDetails = (props) => {
	const [musician, setMusician] = useState(null);

	const { status, error, data, sendRequest } = useHttp(getMusicianById, true);

	useEffect(() => {
		sendRequest(props.id);
	}, []);

	useEffect(() => {
		if (status === "completed" && !error) {
			setMusician(data);
		}
	}, [status, error, setMusician, data]);

	if (status === "pending" || musician === null) {
		return <LoadingSpinner />;
	}

	return (
		<PageMain>
			<div className={style.main}>
				<MainInfo
					entityType="Musician"
					name={`${musician.firstName} ${musician.lastName}`}
					date={musician.dateOfBirth}
					rating={musician.rating}
				/>
				<RatingSection id={musician.id} itemType="musician" />
				<section className={style["detailed-info"]}>
					<MusicianDetailedInfo musician={musician} />
				</section>
			</div>
		</PageMain>
	);
};

export default MusicianDetails;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-context";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faCircleCheck,
	faCircleXmark,
	faCircleUser,
} from "@fortawesome/free-regular-svg-icons";
import {
	faFilter,
	faTrophy,
	faHeart,
	faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FilterContextProvider } from "./store/filter-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
library.add(
	faCircleXmark,
	faCircleCheck,
	faFilter,
	faTrophy,
	faHeart,
	faRightFromBracket,
	faCircleUser
);
root.render(
	<React.StrictMode>
		<AuthContextProvider>
			<FilterContextProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</FilterContextProvider>
		</AuthContextProvider>
	</React.StrictMode>
);

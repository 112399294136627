import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signUpRequest } from "../../api";
import useHttp from "../../hooks/use-http";
import PageMain from "../layout/PageMain";
import LoadingSpinner from "../UI/LoadingSpinner";
import {
	emailValidationCondition,
	usernameValidationCondition,
	passwordValidationCondition,
	invalidEmailMessage,
	invalidUsernameMessage,
	invalidPasswordMessage,
} from "./validation-data";
import FormInput from "../UI/FormInput";
import style from "./SignUpMain.module.css";

const createInputConfig = (
	label,
	id,
	type,
	validationCondition,
	invalidInputMessage
) => {
	return {
		label,
		id,
		type,
		validationCondition,
		invalidInputMessage,
	};
};

const SignUpMain = () => {
	const emailInputRef = useRef();
	const usernameInputRef = useRef();
	const passwordInputRef = useRef();
	const confirmPasswordInputRef = useRef();

	const emailInputConfig = createInputConfig(
		"E-mail",
		"e-mail",
		"email",
		emailValidationCondition,
		invalidEmailMessage
	);

	const usernameInputConfig = createInputConfig(
		"Username",
		"username",
		"text",
		usernameValidationCondition,
		invalidUsernameMessage
	);

	const passwordInputConfig = createInputConfig(
		"Password",
		"password",
		"password",
		passwordValidationCondition,
		invalidPasswordMessage
	);

	const confirmPasswordInputConfig = createInputConfig(
		"Confirm password",
		"confirm-password",
		"password",
		passwordValidationCondition,
		invalidPasswordMessage
	);

	const navigate = useNavigate();

	const { status, error, data, sendRequest } = useHttp(signUpRequest);

	const cancelClickHandler = () => {
		navigate("/home");
	};

	const submitHandler = (event) => {
		event.preventDefault();
		if (
			passwordInputRef.current.value !== confirmPasswordInputRef.current.value
		) {
			return;
		}
		sendRequest({
			email: emailInputRef.current.value,
			username: usernameInputRef.current.value,
			password: passwordInputRef.current.value,
		});

		console.log({
			email: emailInputRef.current.value,
			username: usernameInputRef.current.value,
			password: passwordInputRef.current.value,
		});
	};

	// useEffect(() => {
	// 	if (status === "completed" && !error) {
	// 		navigate("/log-in");
	// 	}
	// }, [status, error, navigate]);

	if (status === "pending") {
		return <LoadingSpinner />;
	}

	if (status === "completed" && error) {
		return <div>{error}</div>;
	}

	return (
		<PageMain>
			<div className={style.main}>
				<h1>Sign up</h1>

				<form onSubmit={submitHandler}>
					<FormInput ref={emailInputRef} inputConfig={emailInputConfig} />
					<FormInput ref={usernameInputRef} inputConfig={usernameInputConfig} />
					<FormInput ref={passwordInputRef} inputConfig={passwordInputConfig} />
					<FormInput
						ref={confirmPasswordInputRef}
						inputConfig={confirmPasswordInputConfig}
					/>
					<div className={style.buttons}>
						<button type="submit">Sign up</button>
						<button type="reset" onClick={cancelClickHandler}>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</PageMain>
	);
};

export default SignUpMain;

import { useEffect, useState } from "react";
import { getPieceById } from "../../api";
import useHttp from "../../hooks/use-http";
import style from "../entity-details/EntityDetails.module.css";
import MainInfo from "../entity-details/MainInfo";
import RatingSection from "../entity-details/RatingSection";
import PageMain from "../layout/PageMain";
import LoadingSpinner from "../UI/LoadingSpinner";
import PieceDetailedInfo from "./PieceDetailedInfo";

const PieceDetails = (props) => {
	const [piece, setPiece] = useState(null);

	const { status, error, data, sendRequest } = useHttp(getPieceById, true);

	useEffect(() => {
		sendRequest(props.id);
	}, []);

	useEffect(() => {
		setPiece(data);
	}, [status, error, setPiece, data]);

	if (status === "pending" || piece === null) {
		return <LoadingSpinner />;
	}

	return (
		<PageMain>
			<div className={style.main}>
				<MainInfo
					entityType="piece"
					name={piece.title}
					date={piece.creationDate}
					rating={piece.rating}
				/>
				<RatingSection id={piece.id} itemType="piece" />
				<section className={style["detailed-info"]}>
					<PieceDetailedInfo piece={piece} />
				</section>
			</div>
		</PageMain>
	);
};

export default PieceDetails;

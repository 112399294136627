import PageMain from "../components/layout/PageMain";
import RankingMain from "../components/ranking/RankingMain";
import { useParams } from "react-router-dom";
import {
	getAllPiecesRanked,
	getAllComposersRanked,
	getAllMusiciansRanked,
	getAllComposersCount,
	getAllPiecesCount,
	getAllMusiciansCount,
} from "../api";

const PIECES_TITLE = "Pieces` Ranking";
const MUSICIANS_TITLE = "Musicians` Ranking";
const COMPOSERS_TITLE = "Composers` Ranking";

const PIECE_TYPE = "PIECE";
const COMPOSER_TYPE = "COMPOSER";
const MUSICIAN_TYPE = "MUSICIAN";

const RankingPage = () => {
	return <RankingMain />;
};

export default RankingPage;

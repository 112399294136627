import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageMain from "../layout/PageMain";
import { AuthContext } from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import { logInRequest } from "../../api";
import LoadingSpinner from "../UI/LoadingSpinner";
import style from "./LogIn.module.css";

const LogInMain = () => {
	const usernameInputRef = useRef();
	const passwordInputRef = useRef();
	const [isEmptyInputs, setIsEmptyInputs] = useState(false);

	const navigate = useNavigate();

	const authCtx = useContext(AuthContext);

	const { status, error, data, sendRequest } = useHttp(logInRequest);

	const submitHandler = (event) => {
		event.preventDefault();
		if (
			usernameInputRef.current.value === "" ||
			passwordInputRef.current.value === ""
		) {
			console.log("in");
			setIsEmptyInputs(true);
			return;
		}
		setIsEmptyInputs(false);
		sendRequest({
			username: usernameInputRef.current.value,
			password: passwordInputRef.current.value,
		});
	};

	const cancelClickHandler = () => {
		navigate("/home");
	};

	useEffect(() => {
		if (status === "completed" && !error) {
			console.log(data.token);
			authCtx.login(data.token);
			navigate("/home");
		}
	}, [status, error, authCtx, data]);

	if (status === "pending") {
		return <LoadingSpinner />;
	}

	return (
		<PageMain>
			<div className={style.main}>
				<h1>Log in</h1>
				<form onSubmit={submitHandler} className={style.form}>
					{isEmptyInputs && <p>Inputs can not be blank!</p>}
					{error && status === "completed" && <p>Bad credentials!</p>}
					<div className={style["input-row"]}>
						<label>Username</label>
						<input id="username" type="text" ref={usernameInputRef} />
					</div>
					<div className={style["input-row"]}>
						<label>Password</label>
						<input id="password" type="password" ref={passwordInputRef} />
					</div>
					<div className={style.buttons}>
						<button type="submit" className={style.submit}>
							Log in
						</button>
						<button
							type="reset"
							onClick={cancelClickHandler}
							className={style.cancel}
						>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</PageMain>
	);
};

export default LogInMain;

import { useEffect, useState } from "react";
import { getComposerById } from "../../api";
import useHttp from "../../hooks/use-http";
import style from "../entity-details/EntityDetails.module.css";

import MainInfo from "../entity-details/MainInfo";
import RatingSection from "../entity-details/RatingSection";
import PageMain from "../layout/PageMain";
import LoadingSpinner from "../UI/LoadingSpinner";
import ComposerDetailedInfo from "./ComposerDetailedInfo";

const ComposerDetails = (props) => {
	const [composer, setComposer] = useState(null);

	const { status, error, data, sendRequest } = useHttp(getComposerById, true);

	useEffect(() => {
		sendRequest(props.id);
	}, []);

	useEffect(() => {
		if (status === "completed" && !error) {
			setComposer(data);
		}
	}, [status, error, data, setComposer]);

	if (status === "pending" || composer === null) {
		return <LoadingSpinner />;
	}

	return (
		<PageMain>
			<div className={style.main}>
				<MainInfo
					entityType="composer"
					name={`${composer.firstName} ${composer.lastName}`}
					date={composer.dateOfBirth}
					rating={composer.rating}
				/>
				<RatingSection id={composer.id} itemType="composer" />
				<section className={style["detailed-info"]}>
					<ComposerDetailedInfo composer={composer} />
				</section>
			</div>
		</PageMain>
	);
};

export default ComposerDetails;

const EMAIL_REGEX =
	/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailValidationCondition = (emailInputValue) => {
	return emailInputValue.match(EMAIL_REGEX);
};

export const usernameValidationCondition = (usernameInputValue) => {
	return usernameInputValue.length === 0 ? false : true;
};

export const passwordValidationCondition = (passwordInputValue) => {
	let isLengthValid =
		passwordInputValue.length > 7 && passwordInputValue.length < 32;
	let hasUppercaseLetter = passwordInputValue.match(/.*[A-Z].*/i)
		? true
		: false;
	let hasSpecialCharacter = passwordInputValue.match(
		/.*[*.!@$%^&(){}[\]:;<>,.?/~_+-=|].*/i
	)
		? true
		: false;
	return isLengthValid && hasUppercaseLetter && hasSpecialCharacter;
};

export const invalidEmailMessage = "E-mail is not valid!";
export const invalidUsernameMessage = "Username can not be blank!";
export const invalidPasswordMessage =
	"Password must contains atleast: \n- 1 uppercase character \n- 1 special character \n - 8 characters";

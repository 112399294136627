import style from "./StarsRating.module.css";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import { saveRatingForItem } from "../../api";

const StarsRating = (props) => {
	const [rating, setRating] = useState(props.rating);
	const [hover, setHover] = useState(props.rating);
	const [rated, setRated] = useState(props.ranked);
	const [isStarRatingConfirmationVisible, setIsStartRatingConfirmationVisible] =
		useState(false);

	const authCtx = useContext(AuthContext);

	const location = useLocation();

	const allStars = 10;

	const { status, error, data, sendRequest } = useHttp(saveRatingForItem);

	useEffect(() => {
		setRating(props.rating);
		setHover(props.rating);
	}, [props.rating, setRating, setHover, rated]);

	useEffect(() => {
		setRated(props.ranked);
	}, [props.ranked, setRated]);

	const onStarClickHandler = (index) => {
		console.log(rated);
		if (rated) {
			return;
		}
		setRating(index);
		setIsStartRatingConfirmationVisible(true);
	};

	const confirmRatingButtonClickHandler = () => {
		setIsStartRatingConfirmationVisible(false);
		const splitted = location.pathname.split("/");
		const itemId = +splitted[3];
		const itemType = splitted[1].substring(0, splitted[1].length - 1);
		console.log(itemId, itemType);
		const requestData = {
			itemType,
			itemId,
			token: authCtx.token,
			rating,
		};
		sendRequest(requestData);
	};

	const cancelRatingButtonClickHandler = () => {
		setIsStartRatingConfirmationVisible(false);
		setRating(0);
		setHover(0);
	};

	const classNames =
		!authCtx.isLoggedIn || rated
			? `${style["star-rating"]} ${style.disabled}`
			: style["star-rating"];

	return (
		<>
			<div className={classNames}>
				{[...Array(allStars)].map((star, index) => {
					index += 1;
					return (
						<button
							type="button"
							key={index}
							className={index <= (rating || hover) ? style.on : style.off}
							onClick={() => onStarClickHandler(index)}
							onMouseEnter={() => setHover(index)}
							onMouseLeave={() => setHover(rating)}
						>
							<span className="star">&#9733;</span>
						</button>
					);
				})}
				{isStarRatingConfirmationVisible && (
					<div className={style["confirmation-buttons"]}>
						<button onClick={confirmRatingButtonClickHandler}>
							<FontAwesomeIcon icon="fa-regular fa-circle-check" />
						</button>
						<button onClick={cancelRatingButtonClickHandler}>
							<FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
						</button>
					</div>
				)}
			</div>
			{!authCtx.isLoggedIn && <p>You need to be logged in to rate!</p>}
		</>
	);
};

export default StarsRating;

import { useContext, useEffect, useState } from "react";
import style from "./EntityDetails.module.css";
import StarsRating from "../UI/StarsRating";
import useHttp from "../../hooks/use-http";
import { getRatingForItem, getRankingPlaceForItem } from "../../api";
import { AuthContext } from "../../store/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserFavourite from "../UI/UserFavourite";

const RatingSection = (props) => {
	const [rankingPlace, setRankingPlace] = useState(null);
	const [rating, setRating] = useState(0);
	const [itemId, setItemId] = useState(props.id);

	const authCtx = useContext(AuthContext);

	const {
		status: rankingPlaceStatus,
		error: rankingPlaceError,
		data: rankingPlaceData,
		sendRequest: sendRankingPlaceRequest,
	} = useHttp(getRankingPlaceForItem, true);

	const {
		status: ratingStatus,
		error: ratingError,
		data: ratingData,
		sendRequest: sendRatingRequest,
	} = useHttp(getRatingForItem, true);

	useEffect(() => {
		setItemId(props.id);
	}, [props.id]);

	useEffect(() => {
		sendRankingPlaceRequest({
			itemType: props.itemType,
			itemId,
		});
	}, [itemId, props.itemType]);

	useEffect(() => {
		if (rankingPlaceStatus === "completed" && !rankingPlaceError) {
			setRankingPlace(rankingPlaceData.rating);
		}
	}, [rankingPlaceStatus, rankingPlaceError, setRankingPlace]);

	useEffect(() => {
		if (authCtx.isLoggedIn) {
			sendRatingRequest({
				token: authCtx.token,
				itemType: props.itemType,
				itemId,
			});
		}
	}, [authCtx.isLoggedIn, authCtx.token, props.itemType, itemId]);

	useEffect(() => {
		if (ratingStatus === "completed" && !ratingError) {
			setRating(ratingData.rating);
		}
	}, [ratingStatus, ratingData, ratingError, setRating]);

	return (
		<section className={style["rating-section"]}>
			<div className={style["ranking-place"]}>
				<FontAwesomeIcon icon="fa-solid fa-trophy" />
				<p>#{rankingPlace} in ranking</p>
			</div>
			<div className={style["user-rating"]}>
				<div>
					<StarsRating
						rating={!ratingError ? rating : 0}
						ranked={!ratingError}
					/>
				</div>
				<UserFavourite itemId={itemId} itemType={props.itemType}/>
			</div>
		</section>
	);
};

export default RatingSection;

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllComposers } from "../../api";
import useHttp from "../../hooks/use-http";
import { usePagination } from "../../hooks/use-pagination";
import PersonEntityListItem from "../entities-database/PersonEntityListItem";
import LoadingSpinner from "../UI/LoadingSpinner";
import Pagination from "../UI/Pagination";
import style from "../entities-database/EntitiesDatabase.module.css";
import { FilterContext } from "../../store/filter-context";

const ComposersList = () => {
	const [composersList, setComposersList] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	const filterCtx = useContext(FilterContext);

	const { status, error, data, sendRequest } = useHttp(getAllComposers, true);

	const { pageNumber, setPageNumber, pagesCount } = usePagination(1, pageCount);

	useEffect(() => {
		if (filterCtx.isMultipleFiltersOn) {
			sendRequest({
				pageNumber,
				ratingFilterValues: filterCtx.ratingFilterValue,
				epoch: filterCtx.epochFilterValue,
				isMultipleFiltersOn: true,
			});
		} else if (filterCtx.isEpochFilterOn) {
			sendRequest({ pageNumber, epoch: filterCtx.epochFilterValue });
		} else if (filterCtx.isRatingFilterOn) {
			sendRequest({
				pageNumber,
				ratingFilterValues: filterCtx.ratingFilterValue,
			});
		} else {
			sendRequest({ pageNumber: pageNumber });
		}
	}, [
		filterCtx.isEpochFilterOn,
		pageNumber,
		filterCtx.epochFilterValue,
		filterCtx.isRatingFilterOn,
		filterCtx.ratingFilterValue,
		filterCtx.isMultipleFiltersOn,
	]);

	useEffect(() => {
		if (status === "completed" && !error) {
			setComposersList(data.entityList);
			setPageCount(data.allPagesCount);
		}
	}, [
		status,
		error,
		setComposersList,
		setPageCount,
		filterCtx.isMultipleFiltersOn,
		filterCtx.isRatingFilterOn,
		filterCtx.isEpochFilterOn,
	]);

	if (status === "pending") {
		return <LoadingSpinner />;
	}

	const listContent = composersList.map((composer) => {
		return (
			<li key={composer.id}>
				<Link to={`/composers/details/${composer.id}`}>
					<PersonEntityListItem person={composer} entityType={"composer"} />
				</Link>
			</li>
		);
	});

	return (
		<div className={style["entity-list"]}>
			<ul>{listContent}</ul>
			<Pagination
				pageNumber={pageNumber}
				pagesCount={pagesCount}
				setPageNumber={setPageNumber}
			/>
		</div>
	);
};

export default ComposersList;

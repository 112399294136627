import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
	getAllByQuery,
	getComposersByQuery,
	getMusiciansByQuery,
	getPiecesByQuery,
} from "../../api";

import style from "./SearchBar.module.css";

const COMPOSER_DETAILS_LINK_PATH = "/composers/details/";
const MUSICIAN_DETAILS_LINK_PATH = "/musicians/details/";
const PIECE_DETAILS_LINK_PATH = "/pieces/details/";

const SearchBar = (props) => {
	const [isActive, setIsActive] = useState(false);
	const [resultList, setResultList] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [isResultPopUpVisible, setIsResultPopUpVisible] = useState(false);

	let apiRequest;
	let linkPath;

	const location = useLocation();

	if (!props.all) {
		if (location.pathname.includes("composers") && !props.all) {
			apiRequest = getComposersByQuery;
			linkPath = COMPOSER_DETAILS_LINK_PATH;
		} else if (location.pathname.includes("musicians") && !props.all) {
			apiRequest = getMusiciansByQuery;
			linkPath = MUSICIAN_DETAILS_LINK_PATH;
		} else if (location.pathname.includes("pieces") && !props.all) {
			apiRequest = getPiecesByQuery;
			linkPath = PIECE_DETAILS_LINK_PATH;
		}
	} else {
		apiRequest = getAllByQuery;
	}

	useEffect(() => {
		if (isActive && inputValue !== "") {
			const timeoutId = setTimeout(async () => {
				const data = await apiRequest(inputValue);
				setResultList(data);
			}, 200);
			return () => clearTimeout(timeoutId);
		}
	}, [isActive, inputValue]);

	useEffect(() => {
		if (isActive && inputValue.length > 1) {
			setIsResultPopUpVisible(true);
		}
		if (isActive && inputValue.length < 1) {
			setIsResultPopUpVisible(false);
			setResultList([]);
		}
	}, [isActive, inputValue, setIsResultPopUpVisible]);

	const inputChangeHandler = (e) => {
		if (isActive === false) {
			setIsActive(true);
		}
		const timeoutId = setTimeout(() => {
			setIsActive(false);
		}, 3000);
		setInputValue(e.target.value);
	};

	const getLinkPathByType = (type) => {
		if (type === "COMPOSER") {
			return COMPOSER_DETAILS_LINK_PATH;
		} else if (type === "MUSICIAN") {
			return MUSICIAN_DETAILS_LINK_PATH;
		} else if (type === "PIECE") {
			return PIECE_DETAILS_LINK_PATH;
		}
	};

	const blurChangeHandler = () => {
		setIsResultPopUpVisible(false);
	};

	const linkClickHandler = () => {
		setIsResultPopUpVisible(false);
		setInputValue("");
	};

	document.addEventListener("mousedown", (e) => {
		let el = e.target;
		do {
			if (el.hasAttribute && el.hasAttribute("data-dropdown")) {
				return;
			}
		} while ((el = el.parentNode));
		setIsResultPopUpVisible(false);
	});

	// in the future add more flexibility with custom filter method

	const resultPopUpContent = resultList.map((result) => {
		return (
			<li key={result.id} className={style["dropdown-item"]}>
				{linkPath && (
					<Link to={`${linkPath}${result.id}`} onClick={linkClickHandler}>
						<div className={style["dropdown-item-inner"]}>
							<p>{result.title}</p>
							<p>{result.type}</p>
						</div>
					</Link>
				)}
				{!linkPath && (
					<Link
						to={`${getLinkPathByType(result.type)}${result.id}`}
						onClick={linkClickHandler}
					>
						<div className={style["dropdown-item-inner"]}>
							<p>{result.title}</p>
							<p>{result.type}</p>
						</div>
					</Link>
				)}
			</li>
		);
	});

	return (
		<div className={props.className} data-testid="search">
			<input type="search" onChange={inputChangeHandler} value={inputValue} />
			{isResultPopUpVisible && (
				<div
					className={!props.all ? style.dropdown : style["dropdown-all"]}
					data-dropdown
					data-testid="dropdown"
				>
					<ul>{resultPopUpContent}</ul>
				</div>
			)}
		</div>
	);
};

export default SearchBar;

import style from "./Pagination.module.css";

const Pagination = (props) => {
	const pageNumbers = [];

	for (let i = 1; i <= props.pagesCount; i++) {
		pageNumbers.push(i);
	}

	const onNumberClickHandler = (e) => {
		props.setPageNumber(e.target.value);
	};

	const onLeftClickHandler = () => {
		props.setPageNumber(props.pageNumber - 1);
	};

	const onRightClickHandler = () => {
		props.setPageNumber(props.pageNumber + 1);
	};

	const pageNumbersContent = pageNumbers.map((number) => {
		return (
			<li key={number}>
				<button
					value={number}
					onClick={onNumberClickHandler}
					className={number == props.pageNumber ? style.active : style.inactive}
				>
					{number}
				</button>
			</li>
		);
	});

	return (
		<nav className={style.main}>
			<h2>Page</h2>
			<div className={style.buttons}>
				{props.pageNumber != 1 && (
					<button onClick={onLeftClickHandler} className={style.arrow}>
						{"<"}
					</button>
				)}
				<ul>{pageNumbersContent}</ul>
				{props.pageNumber != props.pagesCount && (
					<button onClick={onRightClickHandler} className={style.arrow}>
						{">"}
					</button>
				)}
			</div>
		</nav>
	);
};

export default Pagination;

import { useLocation, useParams } from "react-router-dom";
import ComposerDetails from "../components/composers/ComposerDetails";
import MusicianDetails from "../components/musicians/MusicianDetails";
import PieceDetails from "../components/pieces/PieceDetails";
import {
	COMPOSER_ENTITY_TYPE,
	MUSICIAN_ENTITY_TYPE,
	PIECE_ENTITY_TYPE,
	RENDITION_ENTITY_TYPE,
} from "../Constants";
import RenditionDetails from "../components/renditions/RenditionDetails";

const EntityDetailsPage = () => {
	const params = useParams();

	const location = useLocation();

	if (location.pathname.includes(MUSICIAN_ENTITY_TYPE)) {
		return <MusicianDetails id={params.entityId} />;
	} else if (location.pathname.includes(COMPOSER_ENTITY_TYPE)) {
		return <ComposerDetails id={params.entityId} />;
	} else if (location.pathname.includes(PIECE_ENTITY_TYPE)) {
		return <PieceDetails id={params.entityId} />;
	} 
	console.log("in");
	return <RenditionDetails id={params.entityId} />;
};

export default EntityDetailsPage;

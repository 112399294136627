import style from "./RankingItem.module.css";
import { Link } from "react-router-dom";

const RankingPersonItem = (props) => {
	const { id, placeIndex, firstName, lastName, rating } = props.person;

	return (
		<Link to={`${props.linkUrl}${id}`} className={style.main}>
			<p>{placeIndex}</p>
			<div className={style.name}>
				<img
					alt={`${firstName} ${lastName}`}
					src="http://localhost:8080/api/images/50/1"
				/>
				<p>
					{firstName} {lastName}
				</p>
			</div>
			<p>{rating.toFixed(2)}</p>
		</Link>
	);
};

export default RankingPersonItem;

import { useContext, useState } from "react";
import { FilterContext } from "../../store/filter-context";
import StarRatingFilter from "../UI/StarRatingFilter";
import style from "./Filters.module.css";
import { EPOCHS } from "../../Constants";

const Filters = (props) => {
	const filterCtx = useContext(FilterContext);

	const ratingFilterContent = filterCtx.isRatingFilterOn &&
		filterCtx.ratingFilterValue !== null && (
			<h4>
				Rating: {filterCtx.ratingFilterValue.min} -{" "}
				{filterCtx.ratingFilterValue.max}
			</h4>
		);

	const epochButtonClickHandler = (epoch) => {
		if (epoch === filterCtx.epochFilterValue) {
			filterCtx.removeEpochFilter();
			return;
		}
		filterCtx.addEpochFilter(epoch);
	};

	const epochFilterContent = EPOCHS.map((epoch) => {
		const className =
			epoch === filterCtx.epochFilterValue && filterCtx.isEpochFilterOn
				? style.active
				: "";
		return (
			<li key={epoch}>
				<button
					className={className}
					onClick={() => epochButtonClickHandler(epoch)}
				>
					{epoch}
				</button>
			</li>
		);
	});

	return (
		<div className={style["filters-dropdown"]}>
			<div className={style["rating-filter"]}>
				{ratingFilterContent}
				<StarRatingFilter />
			</div>
			{props.isEpochFilterOn && (
				<div className={style["epoch-filter"]}>
					<ul>{epochFilterContent}</ul>
				</div>
			)}
		</div>
	);
};

export default Filters;

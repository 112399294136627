import style from "./RankingMain.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import StarRatingFilter from "../UI/StarRatingFilter";

const RankingMainNav = () => {
	return (
		<>
			<div className={style.nav}>
				<nav>
					<ul>
						<li>
							<NavLink
								to="/ranking/composers"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Composers
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/ranking/musicians"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Musicians
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/ranking/pieces"
								className={({ isActive }) =>
									isActive ? style.active : undefined
								}
							>
								Pieces
							</NavLink>
						</li>
					</ul>
				</nav>
			</div>
		</>
	);
};

export default RankingMainNav;

import { useEffect, useState } from "react";
import style from "../entities-database/EntityListItem.module.css";

const RenditionListItem = (props) => {
	const [rendition, setRendition] = useState(props.rendition);

	useEffect(() => {
		setRendition(props.rendition);
	}, [props.rendition]);

	return (
		<div className={style.main}>
			<p className={style["badge-label"]}>rendition</p>
			<div className={style["item-outer"]}>
				<div className={style["item-inner"]}>
					<p>{rendition.piece.epoch}</p>
					<div className={style["rendition-info"]}>
						<h3 className={style["rendition-piece"]}>
							{rendition.piece.title}
						</h3>
						<p>by</p>
						<h3 className={style["rendition-musician"]}>
							{rendition.musician.firstName} {rendition.musician.lastName}
						</h3>
					</div>
					<div className={style["item-rating"]}>
						<span className={style.star}>&#9733;</span>
						<p className={style["rating-value"]}>
							{rendition.piece.rating.toFixed(2)}
						</p>
						<p>283 231 rates</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RenditionListItem;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { AuthContext } from "../../store/auth-context";
import SearchBar from "../UI/SearchBar";
import style from "./MainNavigation.module.css";

const MainNavigation = () => {
	const [isRankingDropdownVisible, setIsRankingDropdownVisible] =
		useState(false);

	const authCtx = useContext(AuthContext);

	const cssClasses = [
		style["ranking-dropdown"],
		isRankingDropdownVisible
			? style["ranking-dropdown-opened"]
			: style["ranking-dropdown-closed"],
	];

	const openDropdown = () => {
		setIsRankingDropdownVisible(true);
	};

	const closeDropdown = () => {
		setIsRankingDropdownVisible(false);
	};

	const logoutButtonClickHandler = () => {
		authCtx.logout();
	};

	const rankingDropdownContent = (
		<ul>
			<li key="piece_rating">
				<NavLink to="/ranking/pieces">Pieces</NavLink>
			</li>
			<li key="musician_rating">
				<NavLink to="/ranking/musicians">Musicians</NavLink>
			</li>
			<li key="composer_rating">
				<NavLink to="/ranking/composers">Composers</NavLink>
			</li>
		</ul>
	);

	return (
		<header className={style["navigation-header"]}>
			<div className={style["navigation-logo-row"]}>
				<h1>
					Euterp.<span>io</span>
				</h1>
				<div className={style["search-bar"]}>
					<SearchBar all={true} />
				</div>
				{!authCtx.isLoggedIn && (
					<div className={style["auth-buttons"]}>
						<Link to="/log-in">Log in</Link>
						<Link to="/sign-up">Sign up</Link>
					</div>
				)}
				{authCtx.isLoggedIn && (
					<div className={style["logged-in"]}>
						<Link to="/my-account" className={style.account}>
							<FontAwesomeIcon icon="fa-regular fa-circle-user" />
						</Link>
						<button onClick={logoutButtonClickHandler} className={style.logout}>
							<FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
						</button>
					</div>
				)}
			</div>
			<div className={style["navigation-row"]}>
				<nav className={style.navigation}>
					<ul>
						<li key="home">
							<NavLink
								to="/home"
								className={({ isActive }) => (isActive ? "active" : "")}
							>
								Main Page
							</NavLink>
						</li>
						<li key="composers">
							<NavLink
								to="/composers"
								className={({ isActive }) => (isActive ? "active" : "")}
							>
								Composers
							</NavLink>
						</li>
						<li key="pieces">
							<NavLink
								to="/pieces"
								className={({ isActive }) => (isActive ? "active" : "")}
							>
								Pieces
							</NavLink>
						</li>
						<li key="renditions">
							<NavLink
								to="/renditions"
								className={({ isActive }) => (isActive ? "active" : "")}
							>
								Renditions
							</NavLink>
						</li>
						<li key="musicians">
							<NavLink
								to="/musicians"
								className={({ isActive }) => (isActive ? "active" : "")}
							>
								Musicians
							</NavLink>
						</li>
						<li key="ranking" id="ranking">
							<NavLink
								to="/ranking"
								onMouseEnter={openDropdown}
								onMouseLeave={closeDropdown}
							>
								Ranking
							</NavLink>
							{isRankingDropdownVisible && (
								<div
									className={cssClasses.join(" ")}
									data-rankingdropdown
									onMouseEnter={openDropdown}
									onMouseLeave={closeDropdown}
									data-testid="ranking-dropdown"
								>
									{rankingDropdownContent}
								</div>
							)}
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default MainNavigation;

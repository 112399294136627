import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getAllMusicians } from "../../api";
import useHttp from "../../hooks/use-http";
import { FilterContext } from "../../store/filter-context";
import PersonEntityListItem from "../entities-database/PersonEntityListItem";
import { usePagination } from "../../hooks/use-pagination";
import Pagination from "../UI/Pagination";
import style from "../entities-database/EntitiesDatabase.module.css";

const MusiciansList = () => {
	const [musiciansList, setMusiciansList] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	const filterCtx = useContext(FilterContext);

	const { status, error, data, sendRequest } = useHttp(getAllMusicians, true);

	const { pageNumber, setPageNumber, pagesCount } = usePagination(1, pageCount);

	useEffect(() => {
		if (filterCtx.isRatingFilterOn) {
			sendRequest({
				pageNumber,
				ratingFilterValues: filterCtx.ratingFilterValue,
			});
		} else {
			sendRequest({ pageNumber: pageNumber });
		}
	}, [pageNumber, filterCtx.isRatingFilterOn, filterCtx.ratingFilterValue]);

	useEffect(() => {
		if (status === "completed" && !error) {
			setMusiciansList(data.entityList);
			setPageCount(data.allPagesCount);
		}
	}, [
		status,
		error,
		data,
		setMusiciansList,
		setPageCount,
		pageNumber,
		filterCtx.isRatingFilterOn,
	]);

	const listContent = musiciansList.map((musician) => {
		return (
			<li key={musician.id}>
				<Link to={`/musicians/details/${musician.id}`}>
					<PersonEntityListItem person={musician} entityType={"musician"} />
				</Link>
			</li>
		);
	});

	return (
		<div className={style["entity-list"]}>
			<ul>{listContent}</ul>
			<Pagination
				pageNumber={pageNumber}
				pagesCount={pagesCount}
				setPageNumber={setPageNumber}
			/>
		</div>
	);
};

export default MusiciansList;

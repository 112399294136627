import { useEffect, useState } from "react";
import { getRenditionById } from "../../api";
import useHttp from "../../hooks/use-http";
import PageMain from "../layout/PageMain";
import LoadingSpinner from "../UI/LoadingSpinner";
import { Link } from "react-router-dom";
import style from "./RenditionDetails.module.css";
import RatingSection from "../entity-details/RatingSection";
import YoutubeEmbed from "../UI/YoutubeEmbed";

const RenditionDetails = (props) => {
	const [rendition, setRendition] = useState(null);

	const { status, error, data, sendRequest } = useHttp(getRenditionById, true);

	useEffect(() => {
		sendRequest(props.id);
	}, []);

	useEffect(() => {
		console.log(data);
		setRendition(data);
	}, [status, error, setRendition, data]);

	if (status === "pending" || rendition === null) {
		return <LoadingSpinner />;
	}

	const src = `https://www.youtube.com/embed/dQw4w9WgXcQ?`;

	return (
		<PageMain>
			<div className={style.main}>
				<section className={style.piece}>
					<Link to={`/pieces/details/${rendition.piece.id}`}>
						<h3>{rendition.piece.title}</h3>
					</Link>
					<h5>{rendition.piece.creationDate}</h5>
					<div className={style.composer}>
						{rendition.piece.composer ? (
							<Link to={`/composers/details/${rendition.piece.composer.id}`}>
								<h4>
									{rendition.piece.composer.firstName}{" "}
									{rendition.piece.composer.lastName}
								</h4>
							</Link>
						) : (
							<h4>Unknown</h4>
						)}
					</div>
				</section>
				<section className={style.musician}>
					<div className={style["musician-inner"]}>
						<Link to={`/musicians/details/${rendition.musician.id}`}>
							<h3>
								{rendition.musician.firstName} {rendition.musician.lastName}
							</h3>
						</Link>
						<h5>
							{rendition.musician.dateOfBirth}
							{rendition.musician.dateOfDeath
								? ` - ${rendition.musician.dateOfDeath}`
								: ""}
						</h5>
					</div>
					<img
						alt={`${rendition.musician.firstName} ${rendition.musician.lastName}`}
						src={`http://localhost:8080/api/images/50/${Math.floor(
							Math.random() * 9
						)}`}
					/>
				</section>
			</div>
			<RatingSection id={rendition.id} itemType={rendition} />
			<section className={style.video}>
				<h5>Youtube video</h5>
				<YoutubeEmbed src={src} width={850} height={480} />
			</section>
		</PageMain>
	);
};

export default RenditionDetails;

import { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import LoadingSpinner from "../UI/LoadingSpinner";
import RankingPersonItem from "./RankingPersonItem";
import RankingPieceItem from "./RankingPieceItem";
import style from "./TopEntityRatingBox.module.css";

const getCurrentListContent = (listElements, limit, isPerson) => {
	let indexCounter = 0;
	return listElements.slice(0, limit).map((element) => {
		indexCounter++;
		const personItem = {
			id: element.id,
			placeIndex: indexCounter,
			firstName: element.firstName,
			lastName: element.lastName,
			rating: element.rating,
		};
		const pieceItem = {
			id: element.id,
			placeIndex: indexCounter,
			title: element.title,
			rating: element.rating,
		};

		const currentItem = isPerson ? (
			<RankingPersonItem person={personItem} />
		) : (
			<RankingPieceItem piece={pieceItem} />
		);
		return <li key={element.id}>{currentItem}</li>;
	});
};

const TopEntityRatingBox = (props) => {
	const [listElements, setListElements] = useState([]);
	const [listContent, setListContent] = useState("");
	const [isShowMore, setIsShowMore] = useState(false);

	const { status, error, data, sendRequest } = useHttp(props.apiRequest, true);

	useEffect(() => {
		sendRequest();
	}, []);

	const isPerson =
		props.entityType === "MUSICIAN" || props.entityType === "COMPOSER";

	useEffect(() => {
		if (status === "completed" && !error) {
			setListElements(data);
			setListContent(getCurrentListContent(data, 5, isPerson));
		}
	}, [status, error, setListElements, data, setListContent, isPerson]);

	useEffect(() => {
		if (isShowMore) {
			setListContent(getCurrentListContent(listElements, 10, isPerson));
		} else {
			setListContent(getCurrentListContent(listElements, 5, isPerson));
		}
	}, [isShowMore, setListContent, setListContent, listElements, isPerson]);

	let indexCounter = 1;

	const showMoreHandler = (e) => {
		setIsShowMore(!isShowMore);
	};

	if (status === "pending") {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	if (status === "completed" && !error) {
		<div>{error}</div>;
	}

	return (
		<div className={style.main}>
			<h2>
				TOP <span className="red">{props.entityType}</span> ALL TIME
			</h2>
			<div className={style.list}>
				<ul>{listContent}</ul>
			</div>
			<button onClick={showMoreHandler} className={style["show-more"]}>
				{isShowMore ? "Show less" : "Show more"}
			</button>
		</div>
	);
};

export default TopEntityRatingBox;

import { useEffect, useState } from "react";
import style from "../../DetailedInfo.module.css";
import LoadingSpinner from "../UI/LoadingSpinner";

const PieceDetailedInfo = (props) => {
	const [piece, setPiece] = useState(props.piece);

	useEffect(() => {
		setPiece(props.piece);
	}, [props.piece]);

	if (piece === null) {
		return <LoadingSpinner />;
	}

	return (
		<div className={style.info}>
			<div>
				<h4>Title</h4>
				<p>{piece.title}</p>
			</div>
			<div>
				<h4>Creation date</h4>
				<p>{piece.creationDate}</p>
			</div>
			<div>
				<h4>Length</h4>
				<p>{piece.length}</p>
			</div>
			<div>
				<h4>Description</h4>
				<p>{piece.description}</p>
			</div>
			{piece.structure && (
				<div>
					<h4>Structure</h4>
					<p>{piece.structure}</p>
				</div>
			)}
		</div>
	);
};

export default PieceDetailedInfo;

import { useEffect, useState } from "react";
import style from "../entities-database/EntityListItem.module.css";

const PieceListItem = (props) => {
	const [piece, setPiece] = useState(props.piece);

	useEffect(() => {
		setPiece(piece);
	}, [props.piece]);

	return (
		<div className={style.main}>
			<p className={style["badge-label"]}>piece</p>
			<div className={style["item-outer"]}>
				<div className={style["item-inner"]}>
					<p>{piece.epoch}</p>
					<div className={style["item-info"]}>
						<h3>{piece.title}</h3>
						<p>{piece.creationDate}</p>
					</div>
					<div className={style["item-rating"]}>
						<span className={style.star}>&#9733;</span>
						<p className={style["rating-value"]}>{piece.rating.toFixed(2)}</p>
						<p>283 231 rates</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PieceListItem;

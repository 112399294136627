import React from "react";
import useValidation from "../../hooks/use-validation";
import style from "./FormInput.module.css";

const FormInput = React.forwardRef((props, ref) => {
	const { label, id, type, validationCondition, invalidInputMessage } =
		props.inputConfig;

	const {
		inputValue,
		isInputTouched,
		isInputValid,
		inputBlurHandler,
		inputChangeHandler,
		cleanInput,
	} = useValidation(validationCondition);

	const classNames = !isInputValid && isInputTouched ? style.invalid : "";

	return (
		<div className={style.main}>
			<label>{label}</label>
			<input
				className={classNames}
				id={id}
				type={type}
				ref={ref}
				value={inputValue}
				onChange={inputChangeHandler}
				onBlur={inputBlurHandler}
			/>
			{!isInputValid && isInputTouched && (
				<p className={style["error-msg"]}> {invalidInputMessage}</p>
			)}
		</div>
	);
});

export default FormInput;
